import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinkButton from './LinkButton';
import { Button } from '@mui/material';

const MaterialModal = ({ open, handleClose }) => {
  const [modalWidth, setModalWidth] = useState(400);

  useEffect(() => {
    const updateWidth = () => {
      setModalWidth(window.innerWidth < 400 ? window.innerWidth * 0.9 : 400);
    };

    // Set the initial width
    updateWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: '#1e1e1e',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleTazkarti = () => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'Button',
        event_label: 'WAW Button',
        value: 1
      });
    }
    window.open('https://tazkarti.com/#/e/1135', '_blank');
  };
  const handleSaudiButtonClick = () => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'Button',
        event_label: 'Saudia Button',
        value: 1
      });
    }
    window.open('https://mdlbeast.com/events/balad-beast-2025', '_blank');
  };
  const eventDate = new Date("2025-01-30T14:00:00").getTime(); // Set target event date
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  function getTimeLeft() {
    const now = new Date().getTime();
    const difference = eventDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: 'center',
              marginBottom: 2,
              fontWeight: 'bold',
              color: 'white'
            }}
          >
            Upcoming Events
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: 'white',
                color: 'black',
                width: '100%',
                fontSize: '16px', // Default font size
                ':hover': {
                  background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                  fontWeight: 'bold',
                  transform: window.innerWidth >= 1024 ? 1 : 1, // Scale on larger screens only
                  // Adding hover state that targets the nested time boxes
                  '& .time-box': {
                    background: 'transparent', // Make time boxes transparent when button is hovered
                  },
                },
                display: 'flex',
                flexDirection: 'column'
              }}
              onClick={handleSaudiButtonClick}
            >
              Balad Beast 2025<br />
              <span style={{ fontFamily: "'Tajawal', 'Cairo', 'Noto Kufi Arabic', sans-serif", fontSize: '12px' }}>
                30 Jan 2025 - 31 Jan 2025, Jeddah
              </span>
              <Box sx={{ display: "flex", gap: "12px", marginTop: "5px", }}>
                {["days", "hours", "minutes", "seconds"].map((unit, index) => (
                  <Box key={index} className="time-box" sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "5px 5px",
                    background: "#f0f0f0",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    minWidth: "50px",
                  }}>
                    <span style={{ fontSize: "12px", textTransform: "uppercase" }}>{unit}</span>
                    <span style={{ fontSize: "16px" }}>{timeLeft[unit]}</span>
                  </Box>
                ))}
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                background: 'white',
                color: 'black',
                width: '100%',
                fontSize: '16px', // Default font size
                ':hover': {
                  background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                  fontWeight: 'bold',
                  transform: window.innerWidth >= 1024 ? 1 : 1, // Scale on larger screens only
                  '& .time-box': {
                    background: 'transparent', // Make time boxes transparent when button is hovered
                  },
                },
                display: 'flex',
                flexDirection: 'column'
              }}
              onClick={handleTazkarti} // Updated event handler
            >
              E7KKY TO EMPOWER SUMMIT
              <br />
              <span style={{ fontFamily: "'Tajawal', 'Cairo', 'Noto Kufi Arabic', sans-serif", fontSize: '12px' }}>
                FRIDAY, FEBRUARY 21, 2025
              </span>
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );

};

export default MaterialModal;
